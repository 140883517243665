/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetDto } from '../models/AssetDto';
import type { CompanyDto } from '../models/CompanyDto';
import type { CompanySendListItemDto } from '../models/CompanySendListItemDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompanyService {

    /**
     * @returns CompanyDto OK
     * @throws ApiError
     */
    public static getApiCompany(): CancelablePromise<Array<CompanyDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/company',
        });
    }

    /**
     * @param requestBody 
     * @returns CompanyDto OK
     * @throws ApiError
     */
    public static postApiCompany(
requestBody?: CompanyDto,
): CancelablePromise<CompanyDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/company',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns CompanyDto OK
     * @throws ApiError
     */
    public static getApiCompany1(
id: string,
): CancelablePromise<CompanyDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/company/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns CompanyDto OK
     * @throws ApiError
     */
    public static putApiCompany(
id: string,
requestBody?: CompanyDto,
): CancelablePromise<CompanyDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/company/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns number OK
     * @throws ApiError
     */
    public static deleteApiCompany(
id: string,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/company/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param formData 
     * @returns AssetDto OK
     * @throws ApiError
     */
    public static postApiCompanyLogo(
id: string,
formData?: {
file?: Blob;
},
): CancelablePromise<AssetDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/company/{id}/logo',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id 
     * @param formData 
     * @returns AssetDto OK
     * @throws ApiError
     */
    public static putApiCompanyLogo(
id: string,
formData?: {
file?: Blob;
},
): CancelablePromise<AssetDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/company/{id}/logo',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns AssetDto OK
     * @throws ApiError
     */
    public static postApiCompanySendlist(
id: string,
requestBody?: CompanySendListItemDto,
): CancelablePromise<AssetDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/company/{id}/sendlist',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns CompanyDto OK
     * @throws ApiError
     */
    public static postApiCompanyApprove(
id: string,
): CancelablePromise<CompanyDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/company/{id}/approve',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param ownerId 
     * @returns CompanyDto OK
     * @throws ApiError
     */
    public static postApiCompanyOwner(
id: string,
ownerId?: string,
): CancelablePromise<CompanyDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/company/{id}/owner',
            path: {
                'id': id,
            },
            query: {
                'ownerId': ownerId,
            },
        });
    }

}
