import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import CompactLayout from 'src/layouts/compact';
import DashboardLayout from 'src/layouts/dashboard';

// ----------------------------------------------------------------------

// USER
const UserListPage = lazy(() => import('src/pages/user/list'));
const UserAccountPage = lazy(() => import('src/pages/user/account'));
const UserCreatePage = lazy(() => import('src/pages/user/new'));
const UserEditPage = lazy(() => import('src/pages/user/edit'));

// GREETIE
const GreetiePage = lazy(() => import('src/pages/greetie/list'));
const GreetieDetailsPage = lazy(() => import('src/pages/greetie/details'));
const GreetieCreatePage = lazy(() => import('src/pages/greetie/new'));

// MOBILEUSER
const MobileUserListPage = lazy(() => import('src/pages/mobileuser/list'));
const MobileUserEditPage = lazy(() => import('src/pages/mobileuser/edit'));
const MobileUserDetailsPage = lazy(() => import('src/pages/mobileuser/details'));

// VOUCHER
const VoucherPage = lazy(() => import('src/pages/voucher/list'));
const VoucherDetailsPage = lazy(() => import('src/pages/voucher/details'));

// PAYMENT
const PaymentListPage = lazy(() => import('src/pages/payment/list'));
const PaymentDetailsPage = lazy(() => import('src/pages/payment/details'));
const PaymentCreatePage = lazy(() => import('src/pages/payment/new'));
const PaymentEditPage = lazy(() => import('src/pages/payment/edit'));

// SALESREPORT
const SalesReportListPage = lazy(() => import('src/pages/salesreport/list'));
const SalesReportDetailsPage = lazy(() => import('src/pages/salesreport/details'));
const SalesReportCreatePage = lazy(() => import('src/pages/salesreport/new'));

// REPORT
const ReportListPage = lazy(() => import('src/pages/report/list'));
const ReportDetailsPage = lazy(() => import('src/pages/report/details'));
const ReportCreatePage = lazy(() => import('src/pages/report/new'));

// OFFER
const OfferListPage = lazy(() => import('src/pages/offer/list'));
const OfferDetailsPage = lazy(() => import('src/pages/offer/details'));
const OfferCreatePage = lazy(() => import('src/pages/offer/new'));
const OfferEditPage = lazy(() => import('src/pages/offer/edit'));

// TEMPLATE
const TemplateListPage = lazy(() => import('src/pages/template/list'));
const TemplateDetailsPage = lazy(() => import('src/pages/template/details'));
const TemplateCreatePage = lazy(() => import('src/pages/template/new'));
const TemplateEditPage = lazy(() => import('src/pages/template/edit'));

// MARKETING
const MarketingListPage = lazy(() => import('src/pages/marketing/list'));

const Page404 = lazy(() => import('src/pages/404'));

// ----------------------------------------------------------------------

export const superAdminRoutes = [
  {
    element: (
      <RoleBasedGuard hasContent roles={['SuperAdmin']}>
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      </RoleBasedGuard>
    ),
    children: [   
      {
        path: 'user',
        children: [
          { element: <UserListPage />, index: true },
          { path: 'new', element: <UserCreatePage /> },
          { path: 'list', element: <UserListPage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: ':id/account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'greetie',
        children: [
          { element: <GreetiePage />, index: true },
          { path: 'list', element: <GreetiePage /> },
          { path: 'new', element: <GreetieCreatePage /> },
          { path: 'details/:id', element: <GreetieDetailsPage /> },
        ],
      },
      {
        path: 'mobileuser',
        children: [
          { element: <MobileUserListPage />, index: true },
          { path: 'list', element: <MobileUserListPage /> },
          { path: ':id/edit', element: <MobileUserEditPage /> },
          { path: 'details/:id', element: <MobileUserDetailsPage /> },
        ],
      },
      {
        path: 'voucher',
        children: [
          { element: <VoucherPage />, index: true },
          { path: 'list', element: <VoucherPage /> },
          { path: 'details/:id', element: <VoucherDetailsPage /> },
        ],
      },
      {
        path: 'payment',
        children: [
          { element: <PaymentListPage />, index: true },
          { path: 'list', element: <PaymentListPage /> },
          { path: ':id', element: <PaymentDetailsPage /> },
          { path: ':id/edit', element: <PaymentEditPage /> },
          { path: 'new', element: <PaymentCreatePage /> },
        ],
      },
      {
        path: 'salesreport',
        children: [
          { element: <SalesReportListPage />, index: true },
          { path: 'list', element: <SalesReportListPage /> },
          { path: ':id', element: <SalesReportDetailsPage /> },
          { path: 'new', element: <SalesReportCreatePage /> },
        ],
      },
      {
        path: 'report',
        children: [
          { element: <ReportListPage />, index: true },
          { path: 'list', element: <ReportListPage /> },
          { path: ':id', element: <ReportDetailsPage /> },
          { path: 'new', element: <ReportCreatePage /> },
        ],
      },
      {
        path: 'offer',
        children: [
          { element: <OfferListPage />, index: true },
          { path: 'list', element: <OfferListPage /> },
          { path: 'details/:id', element: <OfferDetailsPage /> },
          { path: ':id/edit', element: <OfferEditPage /> },
          { path: 'new/:id?', element: <OfferCreatePage /> },
        ],
      },
      {
        path: 'template',
        children: [
          { element: <TemplateListPage />, index: true },
          { path: 'list', element: <TemplateListPage /> },
          { path: 'details/:id', element: <TemplateDetailsPage /> },
          { path: ':id/edit', element: <TemplateEditPage /> },
          { path: 'new/:id?', element: <TemplateCreatePage /> },
        ],
      },
      {
        path: 'marketing',
        children: [
          { element: <MarketingListPage />, index: true },
          { path: 'list', element: <MarketingListPage /> },
       
        ],
      },
      { path: '404', element: <Page404 /> },
    ],
  },
];
