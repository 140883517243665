/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserRole {
    STAFF = 'Staff',
    MERCHANT_ADMIN = 'MerchantAdmin',
    SUPER_ADMIN = 'SuperAdmin',
    COMPANY_STAFF = 'CompanyStaff',
    COMPANY_ADMIN = 'CompanyAdmin',
}
