/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TemplateType {
    SYSTEM = 'System',
    MERCHANT = 'Merchant',
    COMPANY = 'Company',
}
