import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import { ApiError, SalesReportDto, SalesReportService } from 'src/api';

export interface SalesReportState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  salesReports: SalesReportDto[];
}

const initialState: SalesReportState = {
  loading: false,
  hasError: false,
  error: '',
  salesReports: [],
};

export const salesReportSlice = createSlice({
  name: 'salesReport',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.hasError = false;
      }
      state.loading = action.payload;
    },

    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setSalesReports: (state, action: PayloadAction<SalesReportDto[]>) => {
      state.salesReports = action.payload;
    },
    addSalesReport: (state, action: PayloadAction<SalesReportDto>) => {
      state.salesReports.push(action.payload);
    },
    removeSalesReport: (state, action: PayloadAction<string>) => {
      state.salesReports = state.salesReports.filter((sr) => sr.id !== action.payload);
    },
  },
});

export const { loading, hasError, setSalesReports, addSalesReport, removeSalesReport } =
  salesReportSlice.actions;

export const getSalesReportsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await SalesReportService.getApiSalesReport();
    dispatch(setSalesReports(response));
  } catch (e) {
    dispatch(hasError((e as ApiError).body));
  } finally {
    dispatch(loading(false));
  }
};

export const createSalesReportsAsync =
  (startDate: number, endDate: number): AppThunk =>
  async (dispatch) => {
    try {
      const response = await SalesReportService.postApiSalesReport(startDate, endDate);
      dispatch(addSalesReport(response));
      return true;
    } catch (e) {
      dispatch(hasError((e as ApiError).body));
      throw e;
    }
  };

export const removeSalesReportAsync =
  (salesReportId: string): AppThunk =>
  async (dispatch) => {
    try {
      await SalesReportService.deleteApiSalesReport(salesReportId);
      dispatch(removeSalesReport(salesReportId));
      return true;
    } catch (e) {
      dispatch(hasError((e as ApiError).body));
      throw e;
    }
  };

export const salesReportStateSelector = (state: RootState): SalesReportState => state.salesReport;

export default salesReportSlice.reducer;
