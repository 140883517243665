/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProductCodeType {
    GREETIE = 'Greetie',
    SHOPIFY = 'Shopify',
    AUTO_CREATED = 'AutoCreated',
}
