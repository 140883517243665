import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import { TemplateDto, TemplateMessageDto, TemplateService } from 'src/api';
import { getErrorMessage } from 'src/utils/error';

export interface TemplateMessagePayload {
  templateId: string;
  message: TemplateMessageDto;
}

export interface TemplateState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  templates: TemplateDto[];
  currentTemplate: TemplateDto | null;
  templatesEmpty: boolean;
}

const initialState: TemplateState = {
  templates: [],
  currentTemplate: null,
  loading: false,
  hasError: false,
  error: '',
  templatesEmpty: true,
};

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setCurrentTemplate: (state, action: PayloadAction<TemplateDto | null>) => {
      state.currentTemplate = action.payload;
    },
    setTemplates: (state, action: PayloadAction<TemplateDto[]>) => {
      state.templates = action.payload;
      state.templatesEmpty = action.payload.length === 0;
    },
    update: (state, action: PayloadAction<TemplateDto>) => {
      state.currentTemplate = action.payload;
      state.templates = state.templates.map((template) =>
        template.id === action.payload.id ? action.payload : template
      );
    },
    saving: (state) => {
      state.hasError = false;
      state.error = '';
    },
    setTemplateMessage: (state, action: PayloadAction<TemplateMessagePayload>) => {
      if (state.currentTemplate?.id === action.payload.templateId) {
        const currentTemplate = { ...state.currentTemplate };
        currentTemplate.message = action.payload.message;
        state.currentTemplate = currentTemplate;
      }

      state.templates = state.templates.map((template) => {
        if (template.id === action.payload.templateId) {
          const updatedTemplate = { ...template };
          updatedTemplate.message = action.payload.message;
          return updatedTemplate;
        }
        return template;
      });
    },

    reset: () => initialState,
  },
});

export const {
  loading,
  saving,
  hasError,
  update,
  setCurrentTemplate,
  setTemplates,
  setTemplateMessage,
  reset,
} = templateSlice.actions;

export const getTemplatesAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loading(true));
    const data = await TemplateService.getApiTemplate();
    dispatch(setTemplates(data));
  } catch (e) {
    dispatch(hasError(getErrorMessage(e)));
  } finally {
    dispatch(loading(false));
  }
};

export const getTemplateAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const data = await TemplateService.getApiTemplate1(id);
      dispatch(setCurrentTemplate(data));
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
    }
  };

export const copyTemplateAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const data = await TemplateService.getApiTemplate1(id);

      const newTemplate = {
        ...data,
        id: '',
        name: `(Kopia)-${data.name}`,
        coverUrl: undefined,
        image: undefined,
        isPublished: false,
      } as TemplateDto;
      dispatch(setCurrentTemplate(newTemplate));
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
    }
  };

export const createTemplateAsync =
  (template: TemplateDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await TemplateService.postApiTemplate(template);
      dispatch(update(result));

      return result;
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
      throw e;
    }
  };

export const createTemplateFromGreetieAsync =
  (greetieId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await TemplateService.postApiTemplateFromgreetie(greetieId);
      dispatch(update(result));

      return result;
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
      throw e;
    }
  };

export const updateTemplateAsync =
  (template: TemplateDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await TemplateService.putApiTemplate(template.id!, template);

      dispatch(update(result));

      return result;
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));

      throw e;
    }
  };

export const upsetTemplateMediaAsync =
  (templateId: string, file: Blob): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await TemplateService.postApiTemplateMedia(templateId, { file });
      dispatch(setTemplateMessage({ templateId, message: result } as TemplateMessagePayload));
      return true;
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
      throw e;
    }
  };

export const removeTemplateAsync =
  (templateId: string): AppThunk =>
  async (dispatch) => {
    try {
      // await TemplateService(templateId);
      // dispatch(removeReport(templateId));
      return true;
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
      throw e;
    }
  };

export const templateStateSelector = (state: RootState): TemplateState => state.template;

export default templateSlice.reducer;
