import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import { MobileUserDto, MobileUserService } from 'src/api';
import { getErrorMessage } from 'src/utils/error';

export interface MobileUserState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  mobileUsers: MobileUserDto[];
  currentMobileUser: MobileUserDto | null;
}

const initialState: MobileUserState = {
  mobileUsers: [],
  currentMobileUser: null,
  loading: false,
  hasError: false,
  error: '',
};

export const mobileUserSlice = createSlice({
  name: 'mobileUser',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setMobileUsers: (state, action: PayloadAction<MobileUserDto[]>) => {
      state.mobileUsers = action.payload;
    },
    setMobileUser: (state, action: PayloadAction<MobileUserDto>) => {
      state.currentMobileUser = action.payload;
    },
    updateMobileUser: (state, action: PayloadAction<MobileUserDto>) => {
      if (state.currentMobileUser?.id === action.payload.id)
        state.currentMobileUser = action.payload;

      state.mobileUsers = state.mobileUsers.map((mobileUser) =>
        mobileUser.id === action.payload.id ? action.payload : mobileUser
      );
    },
    addMobileUser: (state, action: PayloadAction<MobileUserDto>) => {
      state.mobileUsers.push(action.payload);
    },
    removeMobileUser: (state, action: PayloadAction<MobileUserDto>) => {
      if (state.currentMobileUser?.id === action.payload.id)
        state.currentMobileUser = action.payload;

      state.mobileUsers = state.mobileUsers.map((mobileUser) =>
        mobileUser.id === action.payload.id ? action.payload : mobileUser
      );
    },
    saving: (state) => {
      state.hasError = false;
      state.error = '';
    },
  },
});

export const {
  addMobileUser,
  loading,
  saving,
  hasError,
  removeMobileUser,
  updateMobileUser,
  setMobileUser,
  setMobileUsers,
} = mobileUserSlice.actions;

export const getMobileUsersAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loading(true));
    const data = await MobileUserService.getApiMobileuser();
    dispatch(setMobileUsers(data));
  } catch (e) {
    dispatch(hasError(getErrorMessage(e)));
  } finally {
    dispatch(loading(false));
  }
};
export const getMobileUserAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const data = await MobileUserService.getApiMobileuser1(id);
      dispatch(setMobileUser(data));
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
    }
  };

export const updateMobileUserAsync =
  (mobileUser: MobileUserDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const userResult = await MobileUserService.putApiMobileuser(mobileUser!.id!, mobileUser);

      dispatch(updateMobileUser(userResult));
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
      throw e
    }
    return true;
  };

export const createMobileUserAsync =
  (mobileUser: MobileUserDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const userResult = await MobileUserService.postApiMobileuser(mobileUser);
      dispatch(addMobileUser(userResult));
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
    }
    return true;
  };

export const removeMobileUserAsync =
  (mobileUserId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const deletedMobileUser = await MobileUserService.deleteApiMobileuser(mobileUserId);

      dispatch(removeMobileUser(deletedMobileUser));
    } catch (e) {
      dispatch(hasError(getErrorMessage(e)));
    }
  };

export const mobileUserStateSelector = (state: RootState): MobileUserState => state.mobileUser;

export default mobileUserSlice.reducer;
