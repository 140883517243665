/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TemplateDto } from '../models/TemplateDto';
import type { TemplateMessageDto } from '../models/TemplateMessageDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TemplateService {

    /**
     * @returns TemplateDto OK
     * @throws ApiError
     */
    public static getApiTemplate(): CancelablePromise<Array<TemplateDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/template',
        });
    }

    /**
     * @param requestBody 
     * @returns TemplateDto OK
     * @throws ApiError
     */
    public static postApiTemplate(
requestBody?: TemplateDto,
): CancelablePromise<TemplateDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/template',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns TemplateDto OK
     * @throws ApiError
     */
    public static getApiTemplate1(
id: string,
): CancelablePromise<TemplateDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/template/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns TemplateDto OK
     * @throws ApiError
     */
    public static putApiTemplate(
id: string,
requestBody?: TemplateDto,
): CancelablePromise<TemplateDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/template/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param greetieId 
     * @returns TemplateDto OK
     * @throws ApiError
     */
    public static postApiTemplateFromgreetie(
greetieId?: string,
): CancelablePromise<TemplateDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/template/fromgreetie',
            query: {
                'greetieId': greetieId,
            },
        });
    }

    /**
     * @param id 
     * @param formData 
     * @returns TemplateMessageDto OK
     * @throws ApiError
     */
    public static postApiTemplateMedia(
id: string,
formData?: {
file?: Blob;
},
): CancelablePromise<TemplateMessageDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/template/{id}/media',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
