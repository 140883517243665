
// ----------------------------------------------------------------------

import { MerchantInfoDto } from "src/api";

export const setWorkspace = (workspace: MerchantInfoDto | null) => {
  if (workspace) {
    localStorage.setItem('WORKSPACE', JSON.stringify(workspace));

   
  } else {
    localStorage.removeItem('WORKSPACE');

  }
};



