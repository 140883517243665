import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import { AssetDto, OfferDto, OfferService, OfferUpdateDto } from 'src/api';

export interface OfferAssetPayload {
  offerId: string;
  asset: AssetDto;
}

export interface OfferState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  offers: OfferDto[];
  currentOffer: OfferDto | null;
  offersEmpty: boolean;
}

const initialState: OfferState = {
  offers: [],
  currentOffer: null,
  loading: false,
  hasError: false,
  error: '',
  offersEmpty: true,
};

export const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setCurrentOffer: (state, action: PayloadAction<OfferDto | null>) => {
      state.currentOffer = action.payload;
    },
    setOffers: (state, action: PayloadAction<OfferDto[]>) => {
      state.offers = action.payload;
      state.offersEmpty = action.payload.length === 0;
    },
    update: (state, action: PayloadAction<OfferDto>) => {
      state.currentOffer = action.payload;
      state.offers = state.offers.map((offer) =>
        offer.id === action.payload.id ? action.payload : offer
      );
    },
    saving: (state) => {
      state.hasError = false;
      state.error = '';
    },
    setOfferImage: (state, action: PayloadAction<OfferAssetPayload>) => {
      if (state.currentOffer?.id === action.payload.offerId) {
        const currentOffer = { ...state.currentOffer };
        currentOffer.image = action.payload.asset;
        state.currentOffer = currentOffer;
      }

      state.offers = state.offers.map((offer) => {
        if (offer.id === action.payload.offerId) {
          const updatedOffer = { ...offer };
          updatedOffer.image = action.payload.asset;
          return updatedOffer;
        }
        return offer;
      });
    },
    reset: () => initialState,
  },
});

export const {
  loading,
  saving,
  hasError,
  update,
  setCurrentOffer,
  setOffers,
  setOfferImage,
  reset,
} = offerSlice.actions;

export const getOffersAsync =
  (merchantId?: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      const data = await OfferService.getApiOffer(merchantId);

      dispatch(setOffers(data));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const getOfferAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      const data = await OfferService.getApiOffer1(id);
      dispatch(setCurrentOffer(data));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const copyOfferAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const data = await OfferService.getApiOffer1(id);

      const newOffer = {
        ...data,
        id: '',
        name: `(Kopia)-${data.name}`,
        isPublished: false,
      } as OfferDto;
      dispatch(setCurrentOffer(newOffer));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const onCreateOfferAsync =
  (offer: OfferDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await OfferService.postApiOffer(offer);
      dispatch(update(result));

      return result;
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const onUpdateOfferAsync =
  (offer: OfferUpdateDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await OfferService.putApiOffer(offer.id!, offer);

      dispatch(update(result));

      return result;
    } catch (e) {
      dispatch(hasError((e as Error).message));

      throw e;
    }
  };

export const upsetOfferImageAsync =
  (offer: OfferDto, file: Blob): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await OfferService.postApiOfferImage(offer.id!, { file });
      dispatch(setOfferImage({ offerId: offer.id!, asset: result } as OfferAssetPayload));
      return true;
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const changeOfferStatusAsync =
  (offerId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await OfferService.putApiOfferChangestatus(offerId);

      dispatch(update(result));

      return result;
    } catch (e) {
      dispatch(hasError((e as Error).message));

      throw e;
    }
  };

export const offerStateSelector = (state: RootState): OfferState => state.offer;

export default offerSlice.reducer;
