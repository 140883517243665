import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  createListenerMiddleware,
} from '@reduxjs/toolkit';

import accountFormReducer from './slices/accountFormSlice';
import authReducer from './slices/authSlice';
import commonReducer from './slices/commonSlice';
import userReducer from './slices/userSlice';
import mobileUserReducer from './slices/mobileUserSlice';
import statisticsReducer from './slices/statisticsSlice';
import merchantReducer from './slices/merchantSlice';
import productReducer from './slices/productSlice';
import productCodeReducer from './slices/productCodeSlice';
import greetieReducer from './slices/greetieSlice';
import salesReportReducer from './slices/salesReportSlice';
import reportReducer from './slices/reportSlice';
import merchantReportReducer from './slices/merchantReportSlice';
import voucherReducer from './slices/voucherSlice';
import redeemReducer from './slices/redeemSlice';
import marketingReducer from './slices/marketingSlice';
import merchantUserReducer from './slices/merchantUserSlice';
import integrationReducer from './slices/integrationSlice';
import offerReducer from './slices/offerSlice';
import templateReducer from './slices/templateSlice';
import companyReducer from './slices/companySlice';


const combinedReducer = combineReducers({
  accountForm: accountFormReducer,
  auth: authReducer,
  common: commonReducer,
  user: userReducer,
  mobileUser: mobileUserReducer,
  statistics: statisticsReducer,
  merchant: merchantReducer,
  product: productReducer,
  productCode: productCodeReducer,
  greetie: greetieReducer,
  voucher: voucherReducer,
  salesReport: salesReportReducer,
  report: reportReducer,
  merchantReport: merchantReportReducer,
  redeem: redeemReducer,
  marketing: marketingReducer,
  merchantuser: merchantUserReducer,
  integration: integrationReducer,
  offer: offerReducer,
  template: templateReducer,
  company: companyReducer
});

const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, any, Action<string>>;

const createBrowserHistory = require('history').createBrowserHistory;

const history = createBrowserHistory();
export { history };
