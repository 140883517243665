import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import { AssetDto, ProductDto, ProductInfoDto, ProductService } from 'src/api';

export interface ProductState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  products: ProductDto[];
  productInfos: ProductInfoDto[];
  currentProduct: ProductDto | null;
  productsEmpty: boolean;
}

const initialState: ProductState = {
  products: [],
  productInfos: [],
  currentProduct: null,
  loading: false,
  hasError: false,
  error: '',
  productsEmpty: true,
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setCurrentProduct: (state, action: PayloadAction<ProductDto | null>) => {
      state.currentProduct = action.payload;
    },
    setProducts: (state, action: PayloadAction<ProductDto[]>) => {
      state.products = action.payload;
      state.productsEmpty = action.payload.length === 0;
    },
    setProductInfos: (state, action: PayloadAction<ProductInfoDto[]>) => {
      state.productInfos = action.payload;
 
    },
    update: (state, action: PayloadAction<ProductDto>) => {
      state.currentProduct = action.payload;
      state.products = state.products.map((product) =>
        product.id === action.payload.id ? action.payload : product
      );
    },
    saving: (state) => {
      state.hasError = false;
      state.error = '';
    },
    setProductImage: (state, action: PayloadAction<AssetDto>) => {
      const currentProduct = { ...state.currentProduct };
      currentProduct.image = action.payload;
      state.currentProduct = currentProduct;

      state.products = state.products.map((product) => {
        if (product.id === currentProduct.id) {
          const updatedProduct = { ...product };
          updatedProduct.image = action.payload;
          return updatedProduct;
        }
        return product;
      });
    },
    reset: (state) => {
      state.products = [];
      state.currentProduct = null;
      state.loading = false;
      state.hasError = false;
      state.error = '';
      state.productsEmpty = true;
    },
  },
});

export const {
  loading,
  saving,
  hasError,
  update,
  setCurrentProduct,
  setProducts,
  setProductInfos,
  setProductImage,
  reset
} = productSlice.actions;

export const getProductsAsync = (merchantId?: string): AppThunk => async (dispatch) => {
  try {
    dispatch(loading(true));
    const data = await ProductService.getApiProduct(merchantId);
    dispatch(setProducts(data));
    dispatch(loading(false));
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};

export const getProductInfosAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loading(true));
    const data = await ProductService.getApiProductInfos();
    dispatch(setProductInfos(data));
    dispatch(loading(false));
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};

export const getProductAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      const data = await ProductService.getApiProduct1(id);
      dispatch(setCurrentProduct(data));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const copyProductAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const data = await ProductService.getApiProduct1(id);

      const newProduct = {
        ...data,
        id: '',
        name: `(Kopia)-${data.name}`,
        coverUrl: undefined,
        images: [],
        image: undefined,
        isPublished: false,
      };
      dispatch(setCurrentProduct(newProduct));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const onCreateProduct =
  (product: ProductDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await ProductService.postApiProduct(product);
      dispatch(update(result));

      return result;
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const onUpdateProduct =
  (product: ProductDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await ProductService.putApiProduct(product.id!, product);

      dispatch(update(result));

      return result;
    } catch (e) {
      dispatch(hasError((e as Error).message));

      throw e;
    }
  };

export const onUpdateProductImage =
  (merchant: ProductDto, file: Blob): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await ProductService.putApiProductImage(merchant.id!, { file });
      dispatch(setProductImage(result));
      return true;
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const onCreateProductImage =
  (merchant: ProductDto, file: Blob): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await ProductService.postApiProductImage(merchant.id!, { file });
      dispatch(setProductImage(result));
      return true;
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const changeProductStatus =
  (productId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await ProductService.putApiProductChangestatus(productId);

      dispatch(update(result));

      return result;
    } catch (e) {
      dispatch(hasError((e as Error).message));

      throw e;
    }
  };

export const productStateSelector = (state: RootState): ProductState => state.product;

export default productSlice.reducer;
