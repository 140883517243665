/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetDto } from '../models/AssetDto';
import type { OfferDto } from '../models/OfferDto';
import type { OfferUpdateDto } from '../models/OfferUpdateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OfferService {

    /**
     * @param merchantId 
     * @returns OfferDto OK
     * @throws ApiError
     */
    public static getApiOffer(
merchantId?: string,
): CancelablePromise<Array<OfferDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/offer',
            query: {
                'merchantId': merchantId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns OfferDto OK
     * @throws ApiError
     */
    public static postApiOffer(
requestBody?: OfferDto,
): CancelablePromise<OfferDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/offer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns OfferDto OK
     * @throws ApiError
     */
    public static getApiOffer1(
id: string,
): CancelablePromise<OfferDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/offer/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns OfferDto OK
     * @throws ApiError
     */
    public static putApiOffer(
id: string,
requestBody?: OfferUpdateDto,
): CancelablePromise<OfferDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/offer/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns OfferDto OK
     * @throws ApiError
     */
    public static putApiOfferChangestatus(
id: string,
): CancelablePromise<OfferDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/offer/{id}/changestatus',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param formData 
     * @returns AssetDto OK
     * @throws ApiError
     */
    public static postApiOfferImage(
id: string,
formData?: {
file?: Blob;
},
): CancelablePromise<AssetDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/offer/{id}/image',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
