import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'yyyy MM dd';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'yyyy MM dd p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : 0;
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export const fDateFromString = (dateString?: string): Date | null => {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString);
  if (date.toString() === 'Invalid Date') {
    return null;
  }
  return date;
};


export function fDateToUnix(date: InputValue): number {
  try {
    return date ? moment(date).unix() : 0;
  } catch (error) {
    return 0;
  }
  
}

export function fUnixToDate(date: number, newFormat?: string): string {
  const fm = newFormat || "YYYY-MM-DD HH:mm";
  return moment.unix(date).format(fm);
}

export function fUnixToFullDate(date: number): string {

  return fUnixToDate(date, "YYYY-MM-DD HH:mm:ss");
}

export function fUnixToDate2(date: number): Date {
  
  return moment.unix(date).toDate();
}

export function fTomorrow(): Date {
  
  return moment(new Date(), "DD-MM-YYYY").add(1, 'days').toDate();
}
